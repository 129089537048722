<template>
	<v-layout v-if="parentTask" row>
		<v-tooltip bottom>
			<template v-slot:activator="{ on }">
				<v-flex shrink row align-start justify-center v-on="on" @click="selectParentTask">
					<v-layout row align-center justify-center>
						<v-icon class="pointer" color="primary">reply</v-icon>
						<v-flex class="pointer primary--text" v-text="parentTaskTitle" />
					</v-layout>
				</v-flex>
			</template>
			<span v-t="'client_workspace.tasks.parenttask'" />
		</v-tooltip>
	</v-layout>
</template>

<script>
import TasksManagerModuleGuard from '@/mixins/ModulesGuards/TasksManager/TasksManagerModuleGuard'

export default {
	name: 'TasksManagerTaskDetailsParentTaskRecall',
	mixins: [TasksManagerModuleGuard],
	props: {
		task: {
			required: true,
			type: Object
		}
	},
	computed: {
		parentTask: function () {
			return this.service.retrieveParentTaskInList(this.task)
		},
		parentTaskTitle: function () {
			let title = this.parentTask.title
			if (this.$te(title)) {
				title = this.$tc(title, 2)
			}
			return title
		}
	},
	methods: {
		selectParentTask: function () {
			if (this.parentTask) {
				this.service.selectTask(this.parentTask)
			}
		}
	}
}
</script>
